import { Injector, Pipe, PipeTransform } from '@angular/core';
import { pipesMap } from './pipes-map';

@Pipe({
  name: 'dynamicPipe',
  standalone: true,
})
export class DynamicPipe implements PipeTransform {
  public constructor(private injector: Injector) {}

  transform(value: any, pipeToken: any, pipeArgs: any | any[]): any {
    if (!pipeToken) {
      return value;
    } else {
      const pipe = this.injector.get<any>(pipesMap[pipeToken]);
      return Array.isArray(pipeArgs)
        ? pipe.transform(value, ...pipeArgs)
        : pipe.transform(value, pipeArgs);
    }
  }
}
