import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'dateTimeFormat', standalone: true })
export class DateTimePipe implements PipeTransform {
  /**
   * DateTimePipe constructor
   * @param {TranslateService} translate
   */
  constructor(private translate: TranslateService) { }

  /**
   * Format DateTime
   * @param {string} value
   * @param {string} format
   * @returns {any}
   */
  transform(value: Date, format?: string): any {
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour:'2-digit',
      minute: '2-digit',
      timeZone: 'UTC',
    };
    // get the initial value
    const initVal = value.toLocaleString(this.translate.currentLang, options);
    return initVal; // needs to be piped into the async pipe
  }
}
